/*validation for chalk the walk event */

export default class chalkthewalk {
    constructor() {

        const walkchalk = document.getElementById('ChalkWalk');

        if (walkchalk) {
            this.chalkWinners();
        }
    }

    chalkWinners() {
        console.log("Chalk the Walk winners");
        $("#wildcard").addClass("hide");

    }
}

