/*validation for 3d print form */

export default class printThreed {
    constructor() {

        const printThree = document.getElementById('three-d-print');

        if (printThree) {
            this.threeDprint();
        }
    }

    threeDprint() {
        console.log("3d print");
        var today = new Date();
        $('#requestDate').addClass("d-none hide");
        $('label[for="requestDate"]').addClass("d-none hide");
        $('#requestDate').attr("value", today);

        $("#ThingiverseDetails").on("input", function(){
            //console.log("change is happening");
            $("#limit_count4").html("("+$(this).val().length+"/3000)");
            //console.log($(this).val().length)
            if($(this).val().length > 3000){
                $(this).val($(this).val().substring(0, 3000));
                $("#limit_count4").html("(3000/3000)");
            }
        });

        $("#request").on("input", function(){
            var pGvalid = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(this.value) && this.value.length;
            var test = $("#request").val();
            var invalidaddress = new RegExp('(@test)|(@example)');
            if (pGvalid){
                if (invalidaddress.test(test)){
                    $("#valid").html("<p class=\"text-danger\">This is not a valid email address.</p>");
                    $("#request").removeClass("is-valid");
                    $("#request").addClass("is-invalid");
                } else {
                    $("#valid").html("<p class=\"text-success\">This is a valid email address.</p>");
                    $("#request").removeClass("is-invalid");
                    $("#request").addClass("is-valid");
                }
            } else {
                $("#valid").html("<p class=\"text-danger\">This is not a valid email address.</p>");
                $("#request").addClass("is-invalid");
                $("#request").removeClass("is-valid");
            }
        });

        $("#fileUpload").on("change", function(){
            var file = $(this).val();
            if (file === ""){
                //if file is blank dont do it
                $("#filename").addClass("d-none");
                $("#FileTitle").removeAttr("required", true);
            } else {
                //file needs some info
                $("#filename").removeClass("d-none");
                $("#FileTitle").attr("required", true);
            };

            var size = (this.files[0].size / 1024  / 1024).toFixed(2);
            if (size > 2 || size < 1) {
                $("#Submit").attr("disabled", true);
                $("#filesize").html("<p>This file is: "+ size +" MB and too large to be submitted. " +
                    "Please enter a link into the description where we can access the file.</p>");
            } else {
                $("#Submit").removeAttr("disabled", true);
                $("#filesize").html("<p>This file is: "+ size +" MB </p>");
            }

        });
        $("#FileTitle").on("input", function(){
            //console.log("change is happening");
            $("#limit_count5").html("("+$(this).val().length+"/100)");
            //console.log($(this).val().length)
            if($(this).val().length > 100){
                $(this).val($(this).val().substring(0, 100));
                $("#limit_count5").html("(100/100)");
            }
        });
        //library card ignore special characters
        //https://stackoverflow.com/questions/12010275/strip-white-spaces-on-input
        $("#FileTitle").bind("input", function(){
            $(this).val(function(_, v){
                return v.replace(/(\.)|(\!)|(\@)|(\#)|(\$)|(\%)|(\^)|(\&)|(\*)|(\()|(\))|(\<)|(\>)|(\[)|(\])|(\{)|(\})|(\|)|(\:)|(\;)|(\/)/g, "");
            });
        });
    }
}

