/*create slider for opening hashes on pages*/

export default class hashOpen {
    constructor() {

        const hash = document.getElementById('hashOpen');

        if (hash) {
            this.openHashes();
        }
    }

    openHashes() {
        console.log("hashes will be opened");
        var hash = window.location.hash;
        var hashlesshash = hash.replace("#", "");

        if(hash){
            console.log(hash);
            var accordionBtnSelect = hash + " .accordion-button" ;
            var accordionAria = "[aria-labelledby='" + hashlesshash + "']";
            console.log(accordionBtnSelect);
            console.log(accordionAria);
            $(accordionBtnSelect).attr("aria-expanded", "true");
            $(accordionBtnSelect).removeClass("collapsed");
            $(accordionAria).addClass('show');

        } else {
            console.log('no hash');
        }

    } //end of openHashes
}

