export default class comingSoonMonth {
    constructor() {
        const comingSoon = document.getElementById('coming-soon');
        if (comingSoon) {
            this.yearComingSoon();
        }
    }

    yearComingSoon() {
        console.log("coming soon now");
        //resource/service selected
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const currentTime = new Date();
        let currentMonth = month[currentTime.getMonth()];
        console.log("Currently " + currentMonth);


        //https://jsfiddle.net/hLzg0n2y/2/ smooth scroll for opening accordions
        $('.collapse').on('shown.bs.collapse', function(e) {
            var $card = $(this).closest('.accordion-item');
            var $open = $($(this).data('parent')).find('.collapse.show');

            var additionalOffset = 0;
            if($card.prevAll().filter($open.closest('.accordion-item')).length !== 0)
            {
                additionalOffset =  $open.height();
            }
            $('html,body').animate({
                scrollTop: $card.offset().top - additionalOffset
            }, 20);
        });

        if(currentMonth == "January"){
            console.log("Its January!");
            //hide may through dec
            $("#may").addClass("d-none");
            $("#jun").addClass("d-none");
            $("#jul").addClass("d-none");
            $("#aug").addClass("d-none");
            $("#sep").addClass("d-none");
            $("#oct").addClass("d-none");
            $("#nov").addClass("d-none");
            $("#dec").addClass("d-none");
        } else if(currentMonth === "February"){
            console.log("Its February!");
            //hide june through dec, jan
            $("#jun").addClass("d-none");
            $("#jul").addClass("d-none");
            $("#aug").addClass("d-none");
            $("#sep").addClass("d-none");
            $("#oct").addClass("d-none");
            $("#nov").addClass("d-none");
            $("#dec").addClass("d-none");
            $("#jan").addClass("d-none");
        } else if(currentMonth === "March"){
            console.log("Its March!");
            //hide july through dec, january, Feb
            $("#jul").addClass("d-none");
            $("#aug").addClass("d-none");
            $("#sep").addClass("d-none");
            $("#oct").addClass("d-none");
            $("#nov").addClass("d-none");
            $("#dec").addClass("d-none");
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
        } else if(currentMonth === "April"){
            console.log("Its April!");
            //hide Aug through dec, jan-march
            $("#aug").addClass("d-none");
            $("#sep").addClass("d-none");
            $("#oct").addClass("d-none");
            $("#nov").addClass("d-none");
            $("#dec").addClass("d-none");
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
        } else if(currentMonth === "May"){
            console.log("Its May!");
            //hide Sept through dec, jan-april
            $("#sep").addClass("d-none");
            $("#oct").addClass("d-none");
            $("#nov").addClass("d-none");
            $("#dec").addClass("d-none");
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
            $("#apr").addClass("d-none");
        } else if(currentMonth === "June"){
            console.log("Its June!");
            //hide Oct through dec, jan-may
            $("#oct").addClass("d-none");
            $("#nov").addClass("d-none");
            $("#dec").addClass("d-none");
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
            $("#apr").addClass("d-none");
            $("#may").addClass("d-none");
        } else if(currentMonth === "July"){
            console.log("Its July!");
            //hide Nov through dec, jan-june
            $("#nov").addClass("d-none");
            $("#dec").addClass("d-none");
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
            $("#apr").addClass("d-none");
            $("#may").addClass("d-none");
            $("#jun").addClass("d-none");
        } else if(currentMonth === "August"){
            console.log("Its August!");
            //hide dec, jan-july
            $("#dec").addClass("d-none");
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
            $("#apr").addClass("d-none");
            $("#may").addClass("d-none");
            $("#jun").addClass("d-none");
            $("#jul").addClass("d-none");
        } else if(currentMonth === "September"){
            console.log("Its September!");
            //hide jan-aug
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
            $("#apr").addClass("d-none");
            $("#may").addClass("d-none");
            $("#jun").addClass("d-none");
            $("#jul").addClass("d-none");
            $("#aug").addClass("d-none");
        } else if(currentMonth === "October"){
            console.log("Its October!");
            //hide jan-september
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
            $("#apr").addClass("d-none");
            $("#may").addClass("d-none");
            $("#jun").addClass("d-none");
            $("#jul").addClass("d-none");
            $("#aug").addClass("d-none");
            $("#sep").addClass("d-none");
            //reveal the second version of jan at bottom
            $("#janAfter").removeClass("d-none");
        } else if(currentMonth === "November"){
            console.log("Its November!");
            var January = $("jan").html();
            var Febrary = $("feb").html();
            //hide jan-october
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
            $("#apr").addClass("d-none");
            $("#may").addClass("d-none");
            $("#jun").addClass("d-none");
            $("#jul").addClass("d-none");
            $("#aug").addClass("d-none");
            $("#sep").addClass("d-none");
            $("#oct").addClass("d-none");
            //reveal the second versions of jan and feb at bottom
            $("#janAfter").removeClass("d-none");
            $("#febAfter").removeClass("d-none");
        } else if(currentMonth === "December"){
            console.log("Its December!");
            //hide jan - november
            $("#jan").addClass("d-none");
            $("#feb").addClass("d-none");
            $("#mar").addClass("d-none");
            $("#apr").addClass("d-none");
            $("#may").addClass("d-none");
            $("#jun").addClass("d-none");
            $("#jul").addClass("d-none");
            $("#aug").addClass("d-none");
            $("#sep").addClass("d-none");
            $("#oct").addClass("d-none");
            $("#nov").addClass("d-none");
            //reveal the second versions of jan, feb, and march at bottom
            $("#janAfter").removeClass("d-none");
            $("#febAfter").removeClass("d-none");
            $("#marAfter").removeClass("d-none");
        } else {
            //what's going here??
        }

    }
}